.galeria {

  .texto {
    margin-bottom: 40px;
  }

  .btn-container {
    margin-top: -83px;
    padding-right: 8px;
    position: inherit;

    // TABLET
    @include mq(m) {
      margin-top: 0px;
      text-align: center;
      margin-bottom: 40px;
    }

    // MOBILE
    @include mq(s) {
      margin-top: 0px;
      text-align: center;
      margin-bottom: 40px;
    }
  }
}

// Galeria caixa
.caixa-galeria {
  margin-bottom: 20px;

  // Foto
  figure {
    float: left;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin-bottom: 5px;
    overflow: hidden;

    img {
      transition: .3s ease-out;
    }
  }

  &:hover {
    figure img {
      transform: scale(1.1);
    }
  }

  // Título
  h2 {
    float: left;
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    color: $cor_fonte_escura;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    transition: .3s ease-out;

    // MOBILE
    @include mq(s) {
      font-size: 15px;
      height: 40px;
    }

    // TABLET
    @include mq(m) {
      height: 40px;
    }
  }

  &:hover {
    h2 {
      color: $cor_primaria;
    }
  }
}

// Lista de fotos detalhe
.galeria-lista {
  float: left;
  width: 100%;
  //margin-top: 20px;

  // MOBILE
  @include mq(s) {
    margin-top: 10px;
  }
}

// Galeria caixa detalhe
.galeria-thumb {
  overflow: hidden;
  //margin-bottom: 20px;
  height: 254px;

  // MOBILE
  @include mq(s) {
    height: auto;
    margin-bottom: 20px;
  }

  // Foto
  figure {
    float: left;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin-bottom: 5px;
    overflow: hidden;

    img {
      transition: .3s ease-out;
    }
  }

  &:hover {
    figure img {
      transform: scale(1.1);
    }
  }

  h5 {
    margin-bottom: 20px;
    padding: 5px;
    font-weight: 600;
    font-size: 16px;
    color: $cor_fonte_escura;

    // MOBILE
    @include mq(s) {
      font-size: 14px;
    }
  }

  &.bloco-catalogo {
    height: 310px;

    // MOBILE
    @include mq(s) {
      height: auto;
    }

    figure {
      box-shadow: none;
    }

    h5 {
      font-size: 15px;
      margin-top: 5px;
      text-align: center;
    }
  }
}
