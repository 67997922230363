// Seção
.blocos-home {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  
  // Máscara
  .mascara {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, .7);
  }
  
  // Título
  .titulo {
    color: #FFF;
  }
  /*.texto {
    text-align: justify;
    color: #FFF;
    text-align: center;
    //margin-top: 40px;
    font-size: 18px;

    // MOBILE
    @include mq(s) {
      font-size: 16px;
    }
  }*/
}

// Carrosel
.carrosel-blocos {
  .slick-prev,
  .slick-next {
    &::before {
      border-color: rgba(#FFF, .8);
    }
    &:hover::before, &:focus::before {
      border-color: $cor_primaria;
    }
  }
}

// Bloco item
.bloco-home {
  width: 100%;
  margin: 10px 30px;
  text-align: center;
  margin-bottom: 40px;
  
  // MOBILE
  @include mq(s) {
    margin: 10px 10px;
  }
  
  // TABLET
  @include mq(m) {
    margin: 10px 20px;
  }
  
  // Ícone
  .bloco-home-icon {
    position: relative;
    display: inline-block;
    background: rgba(#FFF, .1);
    width: 120px;
    height: 120px;
    line-height: 145px;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 20px;
    transition: .3s ease-out;   
    
    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background: rgba(#FFF, .5);
      opacity: 0;            
    }
    
    i {
      font-size: 50px;
      color: #FFF;
      transition: .3s ease-out;
    }
  }
  &:hover .bloco-home-icon::before {
    animation: bloco-pulse .5s;
  }
  
  // Imagem
  .bloco-home-img {
    display: inline-block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-top: 10px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  // Título
  .bloco-home-titulo {
    font-family: $fonte_geral;
    color: #FFF;
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 10px;
    transition: .3s ease-out;

    // MOBILE
    @include mq(s) {
      font-size: 18px;
    }
  }
  &:hover .bloco-home-titulo {
    color: $cor_primaria;
  }
  
  // Texto
  .bloco-home-texto {
    color: #FFF; 
    font-weight: 300;
  }
}

@keyframes bloco-pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(1.3);
  }
}
